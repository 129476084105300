import React from 'react';
import { PageNote } from '../images';
function PageNoteFound(props) {
  return (
    <>

      <h1 className='text-center minMobil:mt-20 laptop:mt-10 font-pageNote text-[50px]'>Oops! Page Note Found</h1>
      <img className='w-[100%] h-[80vh]' src={PageNote}  alt="#" />

    </>
  );
}

export default PageNoteFound;
