import React from 'react';
import { heroImg } from '../../images';

function HeroImg(props) {
    return (
        <div className='w-50 py-4 pl-3'>
            <img className='bg-slate-400 w-full rounded-md' src={heroImg} width={400} height={700} alt="#" /> 
        </div>
    );
}

export default HeroImg;