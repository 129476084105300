import React, { useState } from 'react';
import { style } from '../style';
import { FaPassport } from 'react-icons/fa'


function LogIn(props) {
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false)


    return (
        <div>
            <div className={`${style.container} min-h-[500px] minMobil:mt-0 tablet:mt-20 p-5 image-bg`}>
                <div className='max-w-[400px] min-h-[450px] bg-gray mx-auto rounded-md bg-opacity-70 py-2 px-3 '>
                    <h4 className='text-white text-center font-GaramondSerif minMobil:text-[24px] tablet:text-[28px] leading-[2.5] px-3 border-b border-[gray]  mx-auto '>{"Hello Log in  "}</h4>
                    <div className='flex  flex-col gap-4'>
                        <input placeholder='Mail or username' onChange={(e) => (setMail(e.target.value))} className='input bg-white pl-2 w-[100%] leading-[2] h-9 rounded-md  border-none font-GaramondSerif outline-none' type="text" />
                        <span className='flex items-center justify-between bg-white  w-[100%] leading-[2] h-9 rounded-md '>
                            <input placeholder='Password' value={password} onChange={(e) => (setPassword(e.target.value))} className={`input w-[100%] leading-[2] h-9 rounded-md  border-none pl-2 font-GaramondSerif ${password.length <= 5 && password.length !== 0 ? 'outline-[red]' : 'outline-none'} `} type={'text'} />
                            <button onClick={() => (setShowPass(showPass ? true : false))} className='w-7 h-[100%] border-none '><FaPassport /></button>
                        </span>
                        <div className="w-[100%] flex flex-col items-center justify-center gap-3  ">
                           <button onClick={()=>('')} className='btn btn-outline-secondary text-white border w-75'>Log-in</button>
                           <div>

                           </div>
                        </div>

                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    );
}

export default LogIn;