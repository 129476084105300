import React from 'react';
import { FOOTER, HERO, NAVBAR, SECTION_SIKL } from './index';
import { style } from '../style';

function Home(props) {
    return (
        <div className='w-[100%] min-h-screen flex flex-col justify-between bg-[#1b263b]'>
            <div>
                <header className={`w-full min-h-max ${style.container} `}>
                    <div >
                        <NAVBAR />
                        <HERO />
                    </div>
                </header>
                <div className={`w-[100%] ${style.container}`}>
                    <SECTION_SIKL />
                </div>
            </div>

            <footer className="footer  min-h-max bg-[#232323]">
                <FOOTER />
            </footer>
        </div>
    );
}

export default Home;