import React from "react";
import { Route, Routes } from "react-router-dom";
import { ADMIN, HOME, LOGIN, PAGE_NOTE_FOUND } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";

function App(props) {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HOME />} />
        <Route path="/admin" element={<ADMIN />} />
        <Route path="/log-in" element={<LOGIN />} />
        <Route path="/404" element={<PAGE_NOTE_FOUND />} />
      </Routes>
    </div>
  );
}

export default App;
