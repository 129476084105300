import React, { useState } from 'react';
import { AiFillBuild, AiFillContacts } from 'react-icons/ai';
import { FaFacebookF, FaFileContract, FaGithub, FaInstagram, FaTelegramPlane, FaYoutube } from 'react-icons/fa';
import { style } from '../style';

function Footer(props) {

    const [btn, setBtn] = useState(true)


    return (
        <div className='w-full min-h-[100px]'>
            <div className={` bg-[#000]`}>
                <div className={`${style.container} bg-[#000] min-h-full flex flex-col py-3 items-center justify-between text-white`}>

                    <div className='flex flex-col gap-1 items-start'>
                        <p className='text-[22px] font-BeauSans flex items-center gap-2'><AiFillBuild color='white' /> azizahadovv</p>

                    </div>
                    <div>

                        <ul className="flex items-center  flex-wrap font-BeauSans   justify-center gap-5">
                            <li><a className="no-underline  minMobil:text-[14px] tablet:text-[17px] text-white hover:text-white hover:underline transition-all " href="#">Home</a></li>
                            <li><a className="no-underline  minMobil:text-[14px] tablet:text-[17px] text-white hover:text-white hover:underline transition-all " href="#">About</a></li>
                            <li><a className="no-underline  minMobil:text-[14px] tablet:text-[17px] text-white hover:text-white hover:underline transition-all " href="#">**</a></li>
                            <li><a className="no-underline  minMobil:text-[14px] tablet:text-[17px] text-white hover:text-white hover:underline transition-all " href="#">work</a></li>
                            <li><a className="no-underline  minMobil:text-[14px] tablet:text-[17px] text-white hover:text-white hover:underline transition-all " href="#">contact</a></li>
                        </ul>
                    </div>
                    <div className='flex flex-wrap items-center justify-center gap-5'>

                        <a className="no-underline text-black font-BeauSans minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white" href={'https://www.instagram.com/ahadovv.lv/'}><FaInstagram size={28} /> </a>

                        <a className="no-underline text-black font-BeauSans minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white" href={{}}><FaFacebookF size={28} /> </a>

                        <a className="no-underline text-black font-BeauSans minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white" href={'https://t.me/azizahadovv_Portfolio'}><FaTelegramPlane size={28} /></a>

                        <a className="no-underline text-black font-BeauSans minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white" href={'https://github.com/azizahadovv'}><FaGithub size={28} /> </a>

                        <a className="no-underline text-black font-BeauSans minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white" href={'https://youtube.com/@azizahadov'}><FaYoutube size={28} /></a>

                        <div className='relative'>
                            <span onClick={() => (setBtn(btn ? false : true))} className="no-underline text-black font-BeauSans  minMobil:w-8 tablet:w-10 minMobil:h-8 tablet:h-10 text-center bg-green rounded-full justify-center flex items-center hover:bg-white"><AiFillContacts size={32} /></span>

                            <div className={` ${btn ? 'hidden' : 'block'} bg-gray absolute -top-3 z-50 right-12 rounded-lg py-2 px-3 flex flex-col items-center`}>
                                <span className=' font-BeauSans'> Contacts</span>
                                <a className='no-underline hover:underline font-BeauSans ' href="tel:+998978611199">+998978611199</a>
                                <a className='no-underline hover:underline  font-BeauSans' href="mailto:aziz.ahadovv@yandex.ru">aziz.ahadovv@yandex.ru</a>
                            </div>
                        </div>


                    </div>

                </div>


            </div>

            <div className={` bg-[#000] border-t w-[100%] h-14 flex items-center border-gray mx-auto`}>
                <div className={`${style.container} bg-[#000] gap-3  flex items-center justify-center text-center  flex-wrap w-[100%]  mx-auto`}>
                    <span className='text-[#dad7cd] font-BeauSans'>@azizahadovv</span>
                    <span className='text-[#dad7cd] font-BeauSans'>
                        Front-End Developer
                    </span>
                </div>
            </div>


        </div>
    );
}

export default Footer;