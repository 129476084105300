
import React, { useState } from 'react';
import HeroImg from './heroImg';
import { style } from '../../style';


function Hero(props) {
    const [more, setMore] = useState(false)
    return (
        <div id='home' className={`flex justify-between minMobil:flex-col tablet:flex-row items-center gap-5  ${style.container} `} >
            <div className='w-50  flex minMobil:flex-col border py-3 px-4'>
                <p className='flex flex-col gap-3 text-white font-GaramondSerif text-[18px]'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit officiis eligendi consequatur quibusdam excepturi animi eaque! Doloremque perspiciatis amet molestias alias aut recusandae saepe suscipit quas est! Quidem atque nobis corrupti quasi sapiente quas odio assumenda ducimus quisquam eligendi culpa ipsum {more && <p id='fade' className='fade-line'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Explicabo eaque earum ea expedita atque nihil voluptatibus, quidem deserunt harum amet dolore fuga, quasi magni repellendus blanditiis. </p>}  </p>
                <div className='w-100  flex items-center minMobil:justify-center tablet:justify-end'>
                    <button onClick={() => (setMore(more ? false : true))} className={`btn ${more && 'btn-warning '} w-25  text-white btn-outline-primary font-BeauSans`}>{!more ? 'More' : 'Decrease'}</button>
                </div>
            </div>
            <HeroImg />

        </div>
    );
}

export default Hero;
