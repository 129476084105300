import React, { Component } from 'react';
import { heroImg } from '../../images';


function Section(props) {


  


  return (
    <div>
      <p className='text-center mt-5 border-b p-3 text-white font-GaramondSerif text-[30px] border-white'>About Me</p>
      {/* bu qisimni alohida qo'shilgan  */}

      <section id="portfolio" className="py-16 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-center mb-8">Portfolio</h2>
          <div className="flex flex-wrap justify-center items-start">
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white p-6 shadow-lg rounded-lg">
                <h3 className="text-xl font-semibold mb-4">About Me</h3>
                <p className="text-gray-700">
                  Assalomu alaykum! Men [Ismingiz]man, [Lavozimingiz]. Veb-dasturlash
                  sohasida [Xilma'niy sifatingiz] yoki [Texnik ko'nikmalaringiz] bilan
                  ishlaganman. [Qisqacha yozing qandaydir texnik ko'nikmalar yoki
                  tajribalaringiz haqida.]
                </p>
                <p className="text-gray-700">
                  [Yoshingiz] yoshda, ishlab chiqishni, o'rganishni va yangiliklarni
                  qabul qilishni sevaman. Veb-dizayn, frontend va backend dasturlashga
                  qiziqaman va ularni o'rganmoqdayman.
                </p>
                <p className="text-gray-700">
                  Men [O'qish joyingizni] o'qiganman va [So'ngi ish tajribalaringizni
                  ta'riflang]. O'zimni va komandamni ishonchli va qat'iy ishchi deb
                  hisoblayman.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white p-6 shadow-lg rounded-lg">
                <div className="flex justify-center">
                  <img
                    src={heroImg}
                    alt="Your Profile"
                    className="w-32 h-32 rounded-full mx-auto"
                  />
                </div>
                <h3 className="text-xl font-semibold text-center mt-4">My Skills</h3>
                <ul className="mt-2 text-gray-700">
                  <li>Web Development</li>
                  <li>Frontend Design</li>
                  <li>Responsive Design</li>
                  <li>React and Vue</li>
                  <li>HTML & CSS</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* bu qisimni alohida qo'shilgan  */}
    </div>
  );
}

export default Section;