import React from 'react';

function Admin(props) {
    return (
        <div>
            hello Admin
        </div>
    );
}

export default Admin;