import { useState } from "react";
import { AiFillBuild, AiOutlineFullscreenExit, AiOutlineMenu, AiOutlineUser } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { style } from "../../style";

function Navbar(props) {
    const navigate = useNavigate()
    const [navBtn, setNavBtn] = useState(false)
    const [Bar, setBar] = useState(false)

    return (
        <div className={`${style.container} w-full  min-h-[80px] p-2 `} >
            <div className='navbar flex items-center justify-between border-b border-opacity-[.3] border-[#c5c3c6]'>
                <p className='minMobil:text-[28px] tablet:text-[25px] text-white font-BeauSans flex items-center gap-2'><AiFillBuild /> azizahadovv</p>
                <ul className=" items-center font-BeauSans minMobil:hidden tablet:flex justify-between gap-5">
                    <li><a className="no-underline hover:border-b  transition-all duration-200 text-white" href="#home">Home</a></li>
                    <li><a className="no-underline hover:border-b  transition-all duration-200 text-white" href="#">About</a></li>
                    <li><a className="no-underline hover:border-b  transition-all duration-200 text-white" href="#">**</a></li>
                    <li><a className="no-underline hover:border-b  transition-all duration-200 text-white" href="#">Work</a></li>
                    <li><a className="no-underline hover:border-b  transition-all duration-200 text-white" href="#">Contact</a></li>
                </ul>
                <div className="flex minMobil:hidden tablet:flex items-center justify-between gap-3">
                    <button title="This Section is For Admin Only" className="btn btn-light" onClick={() => (navigate('/log-in   '))}>
                        <AiOutlineUser />
                    </button>
                    <select className="form-select font-BeauSans">
                        <option defaultChecked value="Eng">Eng</option>
                        <option disabled value="Ru">Ru</option>
                        <option disabled value="Uz">Uzb</option>
                    </select>
                </div>

                <div className={`relative  minMobil:block tablet:hidden`}>
                    <button onClick={() => (setNavBtn(true))} className={`minMobil:block tablet:hidden `}><AiOutlineMenu size={28} color="red" /> </button>
                    <div className={` ${navBtn === true ? 'block' : 'hidden'} transition-all  flex flex-row items-center justify-center  onMausNav absolute z-[99] top-5 right-0 w-[150px] min-h-[100px] p-3 bg-[#000] rounded-lg bg-opacity-40`}>

                        <div className="flex  flex-col gap-2  pt-3 items-center ">
                            <button onClick={() => (setNavBtn(false))} className=" absolute top-3 right-2 text-white font-[700]">X</button>
                            <button title="This Section  is For Admin Only" className="btn btn-light w-[100%]  flex items-center justify-center " onClick={() => (navigate('/log-in   '))}>
                                <AiOutlineUser />
                            </button>
                            <select className="form-select  font-BeauSans">
                                <option defaultChecked value="Eng">Eng</option>
                                <option value="Ru">Ru</option>
                                <option value="Uz">Uzb</option>
                            </select>
                            <div>
                                <button onClick={() => (setBar(true), setNavBtn(false))} className="btn btn-light">Menu Bar</button>

                            </div>
                        </div>

                    </div>
                </div>

            </div>


            <div className={`absolute ${Bar ? 'block' : 'hidden'}  z-10 top-0 right-0 bg-opacity-100 bg-[#232323] rounded-md rounded-br-none pt-5 `}>
                <ul className={`w-[250px] h-[350px]  flex flex-col  items-center font-BeauSans  gap-5`}>
                    <li><a className="no-underline text-white" href="#">Home</a></li>
                    <li><a className="no-underline text-white" href="#">About</a></li>
                    <li><a className="no-underline text-white" href="#">**</a></li>
                    <li><a className="no-underline text-white" href="#">work</a></li>
                    <li><a className="no-underline text-white" href="#">contact</a></li>
                    <button className="no-underline text-white cursor-pointer btn absolute top-0  right-0 " onClick={() => setBar(false)}>X</button>

                </ul>
            </div>


        </div>

    );
}

export default Navbar;